export * from "@config/html";
export * from "@config/pwa";
import { ACCOUNT_LIST_ITEMS, ACCOUNT_LIST_TYPES, CONTEXT_MATCH_TYPES, CONTEXT_ON_MATCH_ACTIONS } from "@common/constants";
// WEBPACK HTML TEMPLATE CONSTANTS
export var PRODUCT_NAME = "elemental";
export var GOOGLE_SIGNIN_CLIENT_ID = process.env.IS_LIVE_API ? "3027454208-10hplm7p3oh67gbg5u0fodcjelqi4m5e.apps.googleusercontent.com" : "3027454208-acj516e18i9sjm9joqsor8bctm4bkhra.apps.googleusercontent.com";
export var FIREBASE_CONFIG = {
    apiKey: "AIzaSyCUsxvVILbWoxTmdlXii1NjJ3CGERlt-xo",
    authDomain: "elementaltv-apps.firebaseapp.com",
    databaseURL: "https://elementaltv-apps.firebaseio.com",
    projectId: "elementaltv-apps",
    storageBucket: "elementaltv-apps.appspot.com",
    messagingSenderId: "3027454208",
    appId: "1:3027454208:web:252adc80e80047fa6b0be5",
    measurementId: "G-WLJHQDJ0QV"
};
export var MANIFEST_NAME = "Elemental.TV";
export var MANIFEST_SHORT_NAME = "Elemental.TV";
// END WEBPACK HTML TEMPLATE CONSTANTS
export var RECAPTCHA_SITE_KEY = "6Lc_GcIaAAAAAOLcUakwsz4yIuoEk3tl7AcdEaTB";
export var SMARTLOOK_ID = "9f90605674b101ca871395bc6e54faa27ce9528c";
export var PRISMIC_REPO_NAME = "elemental";
export var PRISMIC_API_URL = process.env.IS_LIVE_API ? "https://play.elemental.tv/prismic" : "https://uplay-elemental.cdn.prismic.io";
export var PRISMIC_API_KEY = process.env.IS_LIVE_API ? "MC5aSG5EVXhBQUFDUUFuakRv.Q--_vUMOIWXvv73vv73vv70r77-977-9Xu-_ve-_ve-_ve-_vVFW77-977-9SnTvv73vv73vv70PXe-_vVUifA" : "MC5acFVkMkJNQUFDQUFBMlB1.H--_ve-_vVnvv73vv70377-9Wgvvv71JMk7vv73vv71nSx0cIkNa77-9GA1177-9TAh977-9";
export var API_URL = process.env.IS_LIVE_API ? "https://play.elemental.tv/v1" : "https://uplay.elemental.tv/v1";
export var CHARGEBEE_SITE = process.env.IS_LIVE_API ? "elemental" : "elemental-test";
export var CHARGEBEE_PUBLISHABLE_KEY = process.env.IS_LIVE_API ? "live_qRRFAHKwrQcHyBL2SDBakkcuY7oEtTEiH" : "test_zHFcdjL9p8zcfFBXnGdIG1QWkBorcWgdv";
export var WEB_TYPE_PX = 1366;
export var TABLET_TYPE_PX = 1024;
export var LARGE_MOBILE_TYPE_PX = 825;
export var MOBILE_TYPE_PX = 768;
export var SMALL_MOBILE_TYPE_PX = 480;
export var CHANNEL_MOBILE_PX = 600;
export var CHANNEL_CONTAINER_MAX_HEIGHT_PX = 400;
export var ASPECT_RATIO_16_9 = 16 / 9;
export var LOGO_SITE_MOBILE = "/assets/media/elemental_logo_small.svg";
export var LOGO_SITE_WEB = "/assets/media/elemental_logo_full.svg";
export var LOGO_DEFAULT = "/channel-logos/svg/color/test_elemental.svg";
export var LOGO_DEFAULT_LEFT = "/channel-logos/svg/left-color/test_elemental.svg";
export var ICON_PROMO = "/assets/media/present_promo_icon.svg";
export var ICON_RESET = "/assets/media/svg/ic_reset.svg";
export var ICON_CHANNELS_SETTINGS = "/assets/media/svg/ic_channels_reset.svg";
export var ICON_CHANNELS_PERSONAL_SETTINGS = "/assets/media/svg/ic_personal_reset.svg";
export var ICON_SETTINGS_RESET = "/assets/media/svg/ic_settings_reset.svg";
export var THUMBNAIL_OFFSET = 240; // in seconds (4 minutes)
export var SUBCATEGORIES_LIMIT = 1;
export var ROUTE_ON_RECORD_INITIAL_PAGE_SIZE = 20;
export var SLIDER_CHANNELS_DEBOUNCE_TIME = 1000; // in milliseconds
export var SLIDER_ICON_MIN_WIDTH = 909;
export var SLIDER_RESIZE_EVENT_TIMEOUT = 500; // in milliseconds
export var SLIDER_SPEED = 500; // Animation speed in milliseconds.
export var SLIDER_TOUCH_THRESHOLD = 500; // To move the slides, the user must swipe a length of (1/touchThreshold) * the width of the slider.
export var DIRECTION_RIGHT = "right";
export var DIRECTION_LEFT = "left";
export var DIRECTION_UP = "up";
export var DIRECTION_DOWN = "down";
export var DIRECTION_NONE = "none";
export var LIST_LEVEL_ONE = "category";
export var LIST_LEVEL_TWO = "subcategory";
export var API_MAX_CATEGORIES_LIMIT = 20; // the maximum number of categories/subcategories for which epg-s can be fetched simultaneously
export var ROUTE_ON_RECORD_PAGE_SIZE = 40;
export var SHOW_ITEM_HEIGHT = 230; //200px height + 30px margin-bottom. Synchronize with _show.scss!
export var SHOW_BIG_ITEM_HEIGHT = 330; //300px height + 30px margin-bottom. Synchronize with _show.scss!
export var SHOW_ITEM_HEIGHT_LOW_RESOLUTION = 210; //180px height + 30px margin-bottom. Synchronize with _show.scss!
export var BACK_LINK_HEIGHT = 84; // see BackLink.js and _back-link.scss
export var CHANNEL_UPCOMING_PAGE_SIZE = 14;
export var TIMEZONE = "Europe/Sofia";
export var TIME_FORMAT = "en-GB";
export var SEARCH_TYPE_ACTIVE = "active";
export var SEARCH_DEBOUNCE_TIME = 600; // milliseconds
export var NOTIFICATION_REMINDER_TIME = 24;
export var FILE_UPLOAD_MAX_SIZE = 5242880;
export var DROPDOWN_KEYBOARD_SEARCH_TIMEOUT = 1500;
export var SNACKBAR_AUTOHIDE_TIMEOUT = 3000;
export var SNACKBAR_VARIANT_SUCCESS = "success";
export var SNACKBAR_VARIANT_ERROR = "error";
export var TOOLTIP_SHOW_DELAY = 1000;
export var MS_RANGE = 30000;
export var LAYOUT_DIRECTIONS = {
    horizontal: "horizontal",
    vertical: "vertical"
};
export var SEARCH_MODES = [
    {
        id: 1,
        label: "searchModeBoth"
    },
    {
        id: 2,
        label: "searchModeTitle"
    },
    {
        id: 3,
        label: "searchModeDescription"
    }
];
export var SEARCH_MODE_DEFAULT_ID = 1;
export var FILTER_TYPE_LIVE = "Live";
export var FILTER_TYPE_RECORDED = "Recorded";
// TODO: add the right classes to some of the categories.
export var CATEGORIES_EPGS_CONFIG = {
    0: {
        iconClass: "icon icon-popular"
    },
    1: {
        iconClass: "icon-2 icon-filters-all"
    },
    100: {
        iconClass: "icon icon-movie"
    },
    110: {
        iconClass: "icon icon-news"
    },
    120: {
        iconClass: "icon icon-show"
    },
    130: {
        iconClass: "icon icon-sport"
    },
    140: {
        iconClass: "icon icon-kids"
    },
    150: {
        iconClass: "icon icon-music"
    },
    160: {
        iconClass: "icon icon-culture"
    },
    170: {
        iconClass: "icon icon-publicistic"
    },
    180: {
        iconClass: "icon icon-educational"
    },
    190: {
        iconClass: "icon icon-hobby"
    },
    210: {
        iconClass: "icon icon-other"
    }
};
export var CATEGORIES_CHANNELS_CONFIG = {
    // TODO change popular class icon when backend changes it from 0
    0: {
        iconClass: "icon icon-popular"
    },
    1: {
        iconClass: "icon-2 icon-filters-all"
    },
    50: {
        iconClass: "icon icon-movie"
    },
    100: {
        iconClass: "icon icon-show"
    },
    150: {
        iconClass: "icon-2 icon-hobby"
    },
    200: {
        iconClass: "icon icon-sport"
    },
    250: {
        iconClass: "icon icon-educational"
    },
    300: {
        iconClass: "icon icon-music"
    },
    400: {
        iconClass: "icon icon-news"
    },
    450: {
        iconClass: "icon-2 icon-foreign-language"
    },
    500: {
        iconClass: "icon icon-kids"
    },
    1000: {
        iconClass: "icon icon-favorite"
    }
};
export var CATEGORIES_ICONS_CONFIG = {
    0: {
        iconClass: "icon-2 icon-filters-all"
    },
    100: {
        iconClass: "icon icon-movie"
    },
    105: {
        iconClass: "icon-2 icon-series"
    },
    110: {
        iconClass: "icon icon-news"
    },
    120: {
        iconClass: "icon icon-show"
    },
    130: {
        iconClass: "icon-2 icon-sport2"
    },
    140: {
        iconClass: "icon icon-kids"
    },
    150: {
        iconClass: "icon icon-music"
    },
    160: {
        iconClass: "icon-2 icon-culture"
    },
    170: {
        iconClass: "icon-2 icon-public"
    },
    180: {
        iconClass: "icon icon-educational"
    },
    190: {
        iconClass: "icon icon-sport"
    },
    210: {
        iconClass: "icon-2 icon-others"
    }
};
export var CATEGORIES_ALL_ID = 1;
export var VOD_CATEGORIES_DEFAULT_OPTIONS = {
    showTitle: true,
    realThumbnails: true,
    showEpisodeName: false
};
export var USE_NEW_ORDER_OBJECTS = true;
export var INPUT_VALIDATION_ALPHABET = "latin";
// RegExs
export var REGEX_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export var REGEX_PASSWORD = /\S+/;
// regex that match exactly 4 digits
export var REGEX_PIN = /\b\d{4}\b/;
export var REGEX_SYMBOLS = /^[^\s]$/;
export var REGEX_ALPHABET = /[\d\W\s\'_']/g;
export var REGEX_ALPHANUMERIC = /[^a-z0-9]/gi;
export var REGEX_NOT_DIGITS = /[^0-9]/gi;
export var REGEX_ADDRESS = /[^a-zA-Z0-9ÄÖäöÀàÂâÆæßÇçÈèÉéÊêËëÎîÏïÔôŒœÙùÛûÜü\-+,.#№:()\s]/g;
export var REGEX_PHONE = /[^0-9+-]/g;
// Routes:
export var LAST_WATCHED = "/most-watched/last";
export var MOST_WATCHED = "/most-watched";
export var VOD = "/vod";
export var ORDER_SUBSCRIPTIONS_ENABLED = true;
export var ROUTE_ON_RECORD = "/on-record";
export var ROUTE_ARCHIVE = "/archive";
export var ROUTE_CATEGORY = "/category";
export var ROUTE_RESTRICTED_GEOGRAPHY = "/restricted-geography";
export var ROUTE_SIGN_IN = "/login";
export var ROUTE_LOGOUT = "users/logout";
export var ROUTE_SIGN_UP = "/register";
export var ROUTE_TOO_MANY_USERS = "/restricted-access";
export var ROUTE_SEARCH = "/search";
export var ROUTE_CHANNEL = "/channel/";
export var ROUTE_GOOGLE_SIGN_IN = process.env.IS_LIVE_API ? "//play.elemental.tv/v1/users/signin/google" : "//uplay.elemental.tv/v1/users/signin/google";
export var ROUTE_ORDER = "/order";
export var ROUTE_ORDER_SUBSCRIPTIONS = "/order/subscriptions";
export var ROUTE_ORDER_PREVIEW = "/order/preview";
export var ROUTE_ORDER_DETAILS = "/order/details";
export var ROUTE_ORDER_ADDRESSES = "/order/addresses";
export var ROUTE_ORDER_PAYMENT = "/order/payment";
export var ROUTE_ORDER_PAYMENT_SUCCESS = "/order/payment-success";
export var ROUTE_ORDER_PAYMENT_FAIL = "/order/payment-fail";
export var ROUTE_LIVE = "/channels";
export var REDIRECT_DEFAULT_ROUTE = "/login";
export var SIGN_IN_DEFAULT_REDIRECT = "/channels";
export var AUTH_BACK_LINK = "//www.elemental.tv";
export var TV_BOX_LINK = "//elemental.tv/settopbox/index.html";
export var ROUTE_NO_MATCH = "/404";
export var ROUTE_ACCOUNT = "/account";
export var ROUTE_ACCOUNT_DELETE = "/account/delete";
// Status codes:
export var STATUS_CODE_SUCCESS = 200;
export var STATUS_CODE_BAD_REQUEST = 400;
// Groups of error codes that require specific actions
// influencing the overall app behavior:
// 200001 = Users: email validation failed.
// 200004 = Users: access denied.
// 200020 = Users: session has expired.
// 200021 = Users: parallel sessions limit exceeded.
export var ERROR_CODES_THAT_REQUIRE_LOGIN = [
    200001,
    200004,
    200020
];
export var ERROR_SUBCODE_GEO_RESTRICTION = 200031;
export var ERROR_SUBCODE_PROXY_RESTRICTION = 200053;
export var ERROR_SUBCODE_EXISTING_USER = 200030;
export var ERROR_SUBCODE_PASSWORDS_DONT_MATCH = 200003;
export var ERROR_SUBCODE_WRONG_CURRENT_PASSWORD = 200019;
export var ERROR_SUBCODE_TOO_MANY_USERS = 200021;
export var ERROR_SUBCODE_NO_GDPR_CONSENT = 200033;
export var ERROR_SUBCODE_LOGIN_LIMIT = 200049;
export var HEADER_HEIGHT_WEB = 60;
// KEY CODES
export var KEY_SPACE = 32;
export var KEY_ENTER = 13;
export var KEY_ESCAPE = 27;
export var KEY_ARROW_RIGHT = 39;
export var KEY_ARROW_LEFT = 37;
export var KEY_ARROW_UP = 38;
export var KEY_ARROW_DOWN = 40;
export var KEY_TAB = 9;
// Routes not to be remembered as last requested route.
// If such route is remembered, after login the app
// enters an infinite loop trying to navigate to
// this route, but because the user is logged in,
// these routes again navigate to login and the
// loop continues until the stack is overflowed.
export var LAST_REQUESTED_ROUTE_IGNORE_LIST = [
    "/forgotten-password",
    "/login",
    "/register",
    "/reset-password"
];
// Player constants
export var VOLUME_MIN = 0;
export var VOLUME_MAX = 1;
export var VOLUME_STEP = 0.01;
export var TIME_TO_SHARE = 60; // in seconds
export var SECONDS_TO_REVERT = 15; // value in seconds
export var TIME_BEFORE_BUFFER = 12; // value in seconds
export var NOW_EPG = 1; // Live video
export var HAS_ACCESS = 1; // Available in subscription
export var CHANGE_PREV_EPG = 30; // value in seconds
export var ENABLE_LIVE_SECONDS = 16; // 4 chunks * 4 sec = 16
export var ONE_HOUR_IN_SECONDS = 3600; // value in seconds
export var INITIAL_TIME_FORMAT = "00:00";
export var PLAYER_HANDLE_WIDTH = 15; // value in px
export var MIN_RESOLUTION = 288; // value in px
export var MIN_HD_RESOLUTION = 700; // value in px
export var TIME_TO_RECOVER = 3000; // in seconds
export var PLAYER_AUTOHIDE_TIMER = 4000;
export var SUBTITLE_OFF_ID = -1;
// Subscription constants
export var SUBSCRIPTION_ACTIVE = "active";
export var SUBSCRIPTION_NON_RENEWING = "non_renewing";
export var SUBSCRIPTION_PLAN_BASIC = "basic";
export var SUBSCRIPTION_PLAN_BASIC_ID = "0000000000000000000000000000000000000000000000000000000000000001";
export var SUBSCRIPTION_PLAN_SPECIAL = "functional";
export var SUBSCRIPTION_PLAN_SPECIAL_ID = "0100000000000000000000000000000000000000000000000000000000000000";
export var SUBSCRIPTION_PLAN_STANDART = "standart";
export var SUBSCRIPTION_PLAN_PREMIUM = "premium";
export var ENTITY_TYPE_PLAN = "plan";
export var ENTITY_TYPE_ADDON = "addon";
export var ENTITY_TYPE_PROMOTION = "promotional_credits";
export var ADDON_DEFAULT_TYPE = "NexboxA95X";
export var COMPONENT_PAYMENT = {
    componentName: "Payments",
    additionalProps: {
        activeTabIndex: 2
    }
};
// [cfTvboxType]: /IMAGE_PATH/
export var TB_BOX_ANDROID_VERSION_MAP = {
    MecoolKM9: "/common/assets/media/android-10.svg",
    MecoolM8S: "/common/assets/media/android-6.svg",
    MediaHubMXV: "/common/assets/media/android-11.svg",
    StrongS3: "/common/assets/media/google-tv.svg"
};
// TODO: We should get this data from prismic, currently its hardcoded
// REMOVE THIS!!!
export var TV_BOX_ID_TO_TYPE_MAP = {
    "STRONG-LEAP-S3": "StrongS3",
    "MEDIAHUB-MXV": "MediaHubMXV",
    "MECOOL-M8S": "MediaHubMXV"
};
// Payment constants
export var PAYMENT_CARD = "card";
export var PAYMENT_OTHER = "other";
export var PAYMENT_BRAINTREE = "braintree";
export var PAYMENT_BRAINTREE_ACCOUNT = process.env.IS_LIVE_API ? "elemental" : "elemental-test";
export var PAYMENT_TYPE_PAYPAL = "paypal";
export var PAYMENT_TYPE_VISA = "visa";
export var PAYMENT_TYPE_MASTERCARD = "mastercard";
export var PAYMENT_TYPE_DISCOVERY = "discover";
export var PAYMENT_TYPE_MAESTRO = "maestro";
export var PAYMENT_TYPE_JCD = "jcb";
export var PAYMENT_TYPE_AMERCANEXPRESS = "americanexpress";
// Payment fields
export var PAYMENT_FIELD_NUMBER = "number";
export var PAYMENT_FIELD_CVC = "cvc";
export var PAYMENT_FIELD_MONTH = "expiryMonth";
export var PAYMENT_FIELD_YEAR = "expiryYear";
export var PAYMENT_FIELD_NAME = "holderName";
// Component Animation Timeout;
export var ANIMATION_TIMEOUT = 300;
export var TRANSITION_TIMEOUT = 400;
export var DOUBLE_CLICK_TIMEOUT = 300;
// Currency
export var CURRENCIES = {
    EUR: "€"
};
// Account modal profile form IDs
export var BILLING_FORM = 1;
export var DELIVERY_FORM = 2;
export var BILLING_FORM_TYPE = "BillingForm";
export var DELIVERY_FORM_TYPE = "ShippingForm";
// Styles
export var BORDER_COLOR = "#e4e4e4";
// Profile - edit personal information input/checkboxes names
export var FIRST_NAME_INPUT = "firstName";
export var LAST_NAME_INPUT = "lastName";
export var COUNTRY_DROPDOWN = "country";
export var PHONE_INPUT = "phone";
export var ZIP_INPUT = "zip";
export var ADDRESS_INPUT = "address";
export var CITY_INPUT = "city";
export var SAME_ADDRESS_CHECKBOX = "sameAddress";
export var GDPR_BILLING_CHECKBOX = "gdprBilling";
export var GDPR_SHIPPING_CHECKBOX = "gdprShipping";
// WriteToUs - input/checkboxes names
export var SUBJECT = "subject";
export var MESSAGE = "message";
export var EMAIL = "email";
// Modal components names
export var FREQUENTLY_ASKED_QUESTIONS = "FrequentlyAskedQuestions";
export var PRIVACY_POLICY = "PrivacyPolicy";
export var COOKIE_POLICY = "CookiePolicy";
export var MISSING_INFO = "PostLoginMultiModal";
export var MESSAGE_MODULE = "MessageModule";
export var TERMS_AND_CONDITIONS = "TermsAndConditions";
export var BOX_SPECIFICATION = "BoxSpecification";
export var ACCOUNT = "Account";
export var PROMOTIONS = "Promotions";
export var PROFILE_INFORMATION = "ProfileInformation";
export var DELETE_ACCOUNT = "DeleteAccount";
export var CHANGE_PASSWORD = "ChangePassword";
export var PAYMENTS = "Payments";
export var SUBSCRIPTION = "Subscription";
export var INVOICES = "Invoices";
export var CANCEL_SUBSCRIPTION = "CancelSubscription";
export var CANCEL_SUBSCRIPTION_FINAL_STEP = "CancelSubscriptionFinalStep";
export var CONTINUE_SUBSCRIPTION = "ContinueSubscription";
export var CHANNELS = "ChannelsModal";
export var SETTINGS = "SettingsModal";
export var PIN = "PinModal";
export var CHARGE_TYPE_RECURRING = "recurring";
//browser update options object
export var BROWSER_UPDATE_NOTIFIER_OPTIONS = {
    required: {
        e: 16,
        f: -3,
        o: 44,
        s: 10.1,
        c: 71,
        samsung: 6.2
    },
    reminderClosed: 168
};
export var CART_STEP_CIRCLE_SIZE_PX = 24;
var ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.mediahub_bg.android.ottplayer.elemental";
var IOS_APP_URL = "itms-apps://apps.apple.com/bg/app/elemental-tv-bulgarian-live-tv/id6478163798";
export var APP_BANNER_DEVICE_INFO = {
    phone: {
        ios: {
            image: "/assets/media/app-banner/phone.png",
            i18n: {
                title: "download",
                message: "ourNewMobileApp"
            },
            storeImage: "common/assets/media/stores/apple-app-store.svg",
            appURL: IOS_APP_URL
        },
        android: {
            image: "/assets/media/app-banner/phone.png",
            i18n: {
                title: "download",
                message: "ourNewMobileApp"
            },
            storeImage: "common/assets/media/stores/google-play-store.svg",
            appURL: ANDROID_APP_URL
        }
    },
    tablet: {
        ios: {
            image: "/assets/media/app-banner/tablet.png",
            i18n: {
                title: "watchEverywhereEasier",
                message: "ourNewMobileApp",
                messageExtra: [
                    "with",
                    "forIOS"
                ]
            },
            storeImage: "common/assets/media/stores/apple-app-store.svg",
            appURL: IOS_APP_URL
        },
        android: {
            image: "/assets/media/app-banner/tablet.png",
            i18n: {
                title: "watchEverywhereEasier",
                message: "ourNewMobileApp",
                messageExtra: [
                    "with",
                    "forAndroid"
                ]
            },
            storeImage: "common/assets/media/stores/google-play-store.svg",
            appURL: ANDROID_APP_URL
        }
    },
    tv: {
        webos: {
            image: "/assets/media/app-banner/tv.png",
            i18n: {
                title: "watchMoreEasily",
                message: "ourNewApp",
                messageExtra: [
                    "with",
                    "forLGSmartTV"
                ]
            },
            storeImage: "common/assets/media/stores/lg-content-store.svg"
        },
        tizen: {
            image: "/assets/media/app-banner/tv.png",
            i18n: {
                title: "watchMoreEasily",
                message: "searchForElemental"
            },
            storeImage: "common/assets/media/stores/samsung-smart-tv.svg"
        }
    }
};
export var BILLING_BLACKLIST = [];
export var DELIVERY_BLACKLIST = [];
export var BILLING_WHITELIST = [];
export var DELIVERY_WHITELIST = [];
export var WRITE_TO_US_ENABLED = true;
export var AUTH_PAGE_CONFIG = {
    REGISTRATION_ENABLED: true,
    FORGOTTEN_PASSWORD_ENABLED: true,
    LOGIN_WITH_GOOGLE_ENABLED: true,
    BACK_BUTTON_ENABLED: true,
    FORM_LOGO_URL: "",
    FORM_LOGO_ALT: "",
    SERVICE_INFO_URL: "",
    ANDROID_TV_BANNER_ENABLED: false,
    SIGN_UP_SUBSCRIBER_NUM_ENABLED: false
};
export var COOKIE_POLICY_URL = "";
export var TERMS_AND_CONDITIONS_URL = "";
export var PRIVACY_POLICY_URL = "";
export var DEFAULT_LOGGED_ROUTE = ROUTE_LIVE;
export var HEADER_ROUTES = [
    {
        route: ROUTE_LIVE,
        enabled: true
    },
    {
        route: MOST_WATCHED,
        enabled: true
    },
    {
        route: ROUTE_ON_RECORD,
        enabled: true
    },
    {
        route: VOD,
        enabled: false
    }
];
export var HEADER_CLOCK_ENABLED = true;
export var ACCOUNT_MENU_NAME_HIDDEN = false;
export var ACCOUNT_MENU = [
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.ACCOUNT_MODAL
    },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.SUBSCRIPTIONS,
        hidden: false,
        // Match context gives you the last matched action
        // If the last matched action is SHOW, the item will be shown
        // If the last matched action is HIDE, the item will be hidden
        // Every match context items should follow the same structure
        // [ACTION, VALUE_A, VALUE_B]
        matchContext: [
            [
                CONTEXT_ON_MATCH_ACTIONS.HIDE,
                {
                    type: CONTEXT_MATCH_TYPES.POINTER,
                    value: "/userDetails/subscription/0/planId"
                },
                {
                    type: CONTEXT_MATCH_TYPES.VALUE,
                    value: SUBSCRIPTION_PLAN_SPECIAL_ID
                }
            ]
        ]
    },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.PROMOTIONS,
        hidden: false,
        matchContext: [
            [
                CONTEXT_ON_MATCH_ACTIONS.HIDE,
                {
                    type: CONTEXT_MATCH_TYPES.POINTER,
                    value: "/currentPlanPrismic"
                },
                {
                    type: CONTEXT_MATCH_TYPES.EMPTY
                }
            ]
        ]
    },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.TV_BOX
    },
    // { type: ACCOUNT_LIST_TYPES.ITEM, item: ACCOUNT_LIST_ITEMS.MESSAGE_MODULE },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.SETTINGS
    },
    {
        type: ACCOUNT_LIST_TYPES.DIVIDER
    },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.TERMS_AND_CONDITIONS
    },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.PRIVACY_POLICY
    },
    {
        type: ACCOUNT_LIST_TYPES.ITEM,
        item: ACCOUNT_LIST_ITEMS.LOG_OUT
    }
];
export var DELETE_IN_CHANGE_PASSWORD_ENABLED = false;
export var DELETE_ACCOUNT_IS_FORM = false;
export var ACCOUNT_TABS = [
    PROFILE_INFORMATION,
    SUBSCRIPTION,
    PAYMENTS,
    INVOICES
];
export var DELIVERY_FORM_ENABLED = true;
// Value in range 0 - 1
export var MAX_PRICE_PERCENT_DIFFERENCE = 0.01;
