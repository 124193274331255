module.exports = {
    HTML_TEMPLATE_LANG: "bg",
    HTML_TEMPLATE_TITLE: "Elemental.TV | Българска телевизия, Сериали, Предавания, Новини и др.",
    HTML_TEMPLATE_DESCRIPTION: "Българска интерактивна телевизия с HD качество. TV канали на живо с, български сериали, новини, филми, спорт и още. Онлайн стрийминг на bnt, btv, nova, kino nova, btv cinema, btv action, diema, eurosport и др.",
    HTML_TEMPLATE_KEYWORDS: "интерактивна онлайн телевизия, на живо, лайв стрийминг, btv, nova, bnt, diema, eurosport, btv action, btv cinema, kino nova, bitelevision, ring",
    HTML_TEMPLATE_OG_TITLE: "Elemental.TV | Интерактивна българска онлайн телевизия за българи в чужбина.",
    HTML_TEMPLATE_OG_DESCRIPTION: "Интерактивна онлайн телевизия, с HD качество, за българи в чужбина. Сериали, новини, филми, кино, спорт и още. Сега и с безплатен абонамент.",
    HTML_TEMPLATE_OG_IMAGE: "/assets/images/facebook.png",
    HTML_TEMPLATE_GOOGLE_TAG_MANAGER_ID: "GTM-K8N8RSK",
    HTML_TEMPLATE_GOOGLE_CHROMECAST_ID: "1868E374",
    HTML_TEMPLATE_COOKIEBOT_ID: "831e4479-9a31-4298-8b25-ef799ee9f88e"
};
